// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-date-chart-with-text-js": () => import("./../src/pages/date_chart_with_text.js" /* webpackChunkName: "component---src-pages-date-chart-with-text-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-with-text-april-js": () => import("./../src/pages/map_with_text_april.js" /* webpackChunkName: "component---src-pages-map-with-text-april-js" */),
  "component---src-pages-map-with-text-july-js": () => import("./../src/pages/map_with_text_july.js" /* webpackChunkName: "component---src-pages-map-with-text-july-js" */),
  "component---src-pages-map-with-text-june-js": () => import("./../src/pages/map_with_text_june.js" /* webpackChunkName: "component---src-pages-map-with-text-june-js" */),
  "component---src-pages-map-with-text-march-js": () => import("./../src/pages/map_with_text_march.js" /* webpackChunkName: "component---src-pages-map-with-text-march-js" */),
  "component---src-pages-map-with-text-may-js": () => import("./../src/pages/map_with_text_may.js" /* webpackChunkName: "component---src-pages-map-with-text-may-js" */),
  "component---src-pages-map-with-text-recent-js": () => import("./../src/pages/map_with_text_recent.js" /* webpackChunkName: "component---src-pages-map-with-text-recent-js" */),
  "component---src-pages-map-with-text-js": () => import("./../src/pages/map_with_text.js" /* webpackChunkName: "component---src-pages-map-with-text-js" */),
  "component---src-pages-race-chart-with-text-js": () => import("./../src/pages/race_chart_with_text.js" /* webpackChunkName: "component---src-pages-race-chart-with-text-js" */)
}

